import { render, staticRenderFns } from "./ChatBotsSubscriptionNeeded.vue?vue&type=template&id=24ecb92e&scoped=true&"
import script from "./ChatBotsSubscriptionNeeded.js?vue&type=script&lang=js&"
export * from "./ChatBotsSubscriptionNeeded.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ecb92e",
  null
  
)

export default component.exports