import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import ChatBotsSubscription from './ChatBotsSubscription.vue'
import ChatBotsConfirmSubscription from './ChatBotsConfirmSubscription.vue'
import SubscriptionNeeded from './SubscriptionNeeded.vue'
import { Payment } from '@/models/payments/Payment'
import EventBus from '@/util/EventBus'

export default {
  name: 'ChatBotsSubscriptionNeeded',
  components: {
    HeaderTopDashboard,
    SubscriptionNeeded,
    ChatBotsSubscription,
    ChatBotsConfirmSubscription,
  },
  data () {
    return {
      payment: new Payment(),
      isAdditional: null,
    }
  },
  computed: {
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
  },
  methods: {
  },
  created () {
    // // <!-- App Preview Whatsapp -->
    // if (!this.canWhatsapp) {
    //   this.$router.push({ name: 'Home', query: null }).catch(() => { this.$forceUpdate() })
    // }
    EventBus.$emit('showLoading', false)
  },
  mounted () {
    this.isAdditional = this.$route.query.isAdditional
  },
}
