import getSymbolFromCurrency from 'currency-symbol-map'
export class Payment {
  activeCreditCard = false
  activeMultibanco = false
  activeBoleto = false
  activePaypal = false
  activePlan = false
  activeSubscription = null
  activeTransfer = null
  ammount = ''
  bankTransferInfo = null
  billingProfiles = null
  currency = null
  complete = false
  defaultCreditCardId = '0'
  deletedCreditcardId = 0
  entity = null
  fromPlans = false
  invoice = false
  invoiceTmp = false
  infoBillingIsoCode = false
  paymentsInfo = null
  paymentMethod = { bankTransfer: 1, creditCard: 2, paypal: 3, multiBanco: 4, boleto: 5 }
  paymentDetail = null
  paymentMethodSelectedId = false
  reference = null
  step = 1
  show = false
  showInvoice = false
  showUserProfile = false
  showCreditCardDelete = false
  urlCreditCardPay = null

  creditCardOptions = {
    subscribed_card: '0',
    rememberCreditCard: true,
  }

  isSelectedBankTransferPaymentMethod () {
    if (this.paymentMethod.bankTransfer === this.paymentMethodSelectedId) {
      return true
    }
  }

  isSelectedMultibancoPaymentMethod () {
    if (this.paymentMethod.multiBanco === this.paymentMethodSelectedId) {
      return true
    }
  }

  isSelectedBoletoPaymentMethod () {
    if (this.paymentMethod.boleto === this.paymentMethodSelectedId) {
      return true
    }
  }

  isSelectedCreditCardPaymentMethod () {
    if (this.paymentMethod.creditCard === this.paymentMethodSelectedId) {
      return true
    }
  }

  setCurrencyCode () {
    if (this.currency.code === 'COP') {
      return getSymbolFromCurrency(this.currency.code) + ' ' + this.currency.code
    }

    return getSymbolFromCurrency(this.currency.code)
  }

  reset () {
    this.complete = false
    this.amount = ''
    this.invoice = false
    this.invoiceTmp = false
    this.paymentDetail = null
    this.paymentMethodSelectedId = false
    this.defaultCreditCardId = 0
    this.entity = null
    this.reference = null

    this.creditCardOptions = {
      subscribed_card: '0',
      rememberCreditCard: true,
    }
  }
}
